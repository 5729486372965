let ReflectionRouter = [
  {
    path: "/saint-of-the-day",
    name: "SaintOfTheDay",
    meta: { i18n_title: "pgtitle.saintoftheday" },
    component: () =>
      import(
        /* webpackChunkName: "l-SaintOfTheDayHome" */ "@/pages/SaintOfTheDay.vue"
      ),
  },
  {
    path: "/saint-of-the-day/home",
    name: "SaintOfTheDayHome",
    meta: { i18n_title: "pgtitle.saintoftheday" },
    component: () =>
      import(
        /* webpackChunkName: "l-SaintOfTheDayHome" */ "@/pages/FilterHome.vue"
      ),
  },
];

export default ReflectionRouter;
