let ReflectionRouter = [
  {
    path: "/prayers",
    name: "Prayers",
    meta: { i18n_title: "pgtitle.prayers" },
    component: () =>
      import(/* webpackChunkName: "l-prayers" */ "@/pages/Prayers.vue"),
  },
];

export default ReflectionRouter;
