let ReflectionRouter = [
  {
    path: "/reflection",
    name: "Reflection",
    meta: { i18n_title: "pgtitle.reflection" },
    component: () =>
      import(/* webpackChunkName: "l-reflection" */ "@/pages/Reflection.vue"),
  },
  {
    path: "/reflection/home",
    name: "ReflectionHome",
    meta: { i18n_title: "pgtitle.reflection" },
    component: () =>
      import(/* webpackChunkName: "l-reflection" */ "@/pages/FilterHome.vue"),
  },
];

export default ReflectionRouter;
