import { createRouter, createWebHistory } from "vue-router";

import Reflection from "@/router/Reflection.js";
import MassReading from "@/router/MassReading.js";
import SaintOfTheDay from "@/router/SaintOfTheDay.js";
import Prayers from "@/router/Prayers.js";
import { $i18N } from "@/utility/util.js";

const routes = [
  {
    path: "/",
    name: "Home",
    meta: { i18n_title: "pgtitle.home" },
    component: () => import(/* webpackChunkName: "Home" */ "@/pages/Home.vue"),
  },
  {
    path: "/:pathMatch(.*)*",
    name: "NotFound",
    meta: { i18n_title: "pgtitle.notfound" },
    component: () => import("@/pages/NotFound.vue"),
  },
  {
    path: "/vatican-news",
    name: "VaticanNews",
    meta: { i18n_title: "pgtitle.vaticannews" },
    component: () => import("@/pages/VaticanNews.vue"),
  },
  {
    path: "/about-me",
    name: "AboutMe",
    meta: { i18n_title: "pgtitle.aboutme" },
    component: () => import("@/pages/AboutMe.vue"),
  },
  {
    path: "/vatican-news/saint-rita-of-cascia",
    name: "SaintRitaOfCascia",
    meta: { i18n_title: "pgtitle.saintritaofcascia" },
    component: () => import("@/pages/SaintRitaOfCascia.vue"),
  },
  {
    path: "/prayers/how-to-pray-the-rosary",
    name: "HowToPrayTheRosary",
    meta: { i18n_title: "pgtitle.howtopraytherosary" },
    component: () => import("@/pages/HowToPrayTheRosary.vue"),
  },
  {
    path: "/prayers/rosary-prayers",
    name: "RosaryPrayers",
    meta: { i18n_title: "pgtitle.rosaryprayers" },
    component: () => import("@/pages/RosaryPrayers.vue"),
  },
  {
    path: "/prayers/mysteries-of-the-rosary",
    name: "MysteriesOfTheRosary",
    meta: { i18n_title: "pgtitle.mysteriesoftherosary" },
    component: () => import("@/pages/MysteriesOfTheRosary.vue"),
  },
].concat(Reflection, MassReading, Prayers, SaintOfTheDay);

const router = createRouter({
  history: createWebHistory(),
  routes,
  scrollBehavior(to, from, savedPosition) {
    if (to.hash) {
      return {
        el: to.hash,
        behavior: "smooth",
      };
    } else if (savedPosition) {
      return savedPosition;
    } else {
      return { top: 0 };
    }
  },
});

router.afterEach((to) => {
  let {
    meta: { i18n_title = "pgtitle.home" },
  } = to;
  document.title = $i18N(i18n_title);
});

export default router;
