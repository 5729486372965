let ReflectionRouter = [
  {
    path: "/mass-reading",
    name: "MassReading",
    meta: { i18n_title: "pgtitle.massreading" },
    component: () =>
      import(/* webpackChunkName: "l-massreading" */ "@/pages/MassReading.vue"),
  },
  {
    path: "/mass-reading/home",
    name: "MassReadingHome",
    meta: { i18n_title: "pgtitle.massreading" },
    component: () =>
      import(/* webpackChunkName: "l-massreading" */ "@/pages/FilterHome.vue"),
  },
];

export default ReflectionRouter;
